// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11022(c829569e5d)-C13/12/2024-14:43:47-B13/12/2024-14:48:03' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11022(c829569e5d)-C13/12/2024-14:43:47-B13/12/2024-14:48:03",
  branch: "master",
  latestTag: "6.1",
  revCount: "11022",
  shortHash: "c829569e5d",
  longHash: "c829569e5d91a0b12b8bc65c2007ceabcb6152f0",
  dateCommit: "13/12/2024-14:43:47",
  dateBuild: "13/12/2024-14:48:03",
  buildType: "Ansible",
  } ;
